import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from "@mui/material";
import GridCountdown from './GridCountdown';
import ProductOptionsDialog from './ProductOptionsDialog';

const Product = (props) => {
  const { product } = props;

  const [style, setStyle] = useState({
    display: 'none'
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const image1 = product.variants
    ? product.variants[0].image
    : product.images[0];
  const image2 = product.images[1];
  const firstImgSrc = image1 ? image1.transformedSrc : '';
  const [imageSrc, setImageSrc] = useState(firstImgSrc);

  const handleImgHover = () =>
    setImageSrc((prev) => (image2 ? image2.transformedSrc : prev));
  const handleImgOut = () => setImageSrc(image1 ? image1.transformedSrc : '');



  return (
    <>

      <div className="Product" key={product.id}>
        <div
          className="Product__ImageContainer"
          onMouseEnter={() => {
            handleImgHover();
          }}
          onMouseLeave={() => {
            handleImgOut();
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDialogOpen();
          }}
        >
          {image1 ? (
            <img
              className="Product__Image"
              src={imageSrc}
              alt={`${product.title} product shot`}
            />
          ) : (
            <img
              className="Product__Image"
              src="placeholder-image.png"
              alt={`${product.title} product shot`}
            />
          )}

          {product.endDate && <GridCountdown date={product.endDate} />}

          <Button
            className="Product__OpenDetailsButton"
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              handleDialogOpen();
            }}
          >
            SELECT OPTIONS
          </Button>
        </div>
        <div className="Product__info">
          <h4 className="Product__subtitle">{product.title}</h4>
          <p className="Product__price">${product.variants[0].price.amount}</p>

        </div>
      </div>
      <ProductOptionsDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        product={product}
      />
    </>
  );
};

Product.propTypes = {
  product: PropTypes.object
};

export default Product;

